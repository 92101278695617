import React, { createContext, useState, useEffect } from 'react';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const AuthContext = createContext();

const AuthProvider = ({ children, props }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [showUser, setShowUser] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [showOwner, setShowOwner] = useState(false);

  const [OwnedProducts, setOwnedProducts] = useState(false);
  const [AvailableProducts, setAvailableProducts] = useState(false);

  useEffect(async () => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);

      try {
        const result = await UserService.getContexto();
        if (result) {
          setOwnedProducts(result.ownedProducts);
          setAvailableProducts(result.availableProducts);
          setShowUser(result.roles.includes('ROLE_USER'));
          setShowAdmin(result.roles.includes('ROLE_ADMIN'));
          setShowMaster(result.roles.includes('ROLE_MASTER'));
          setShowOwner(result.roles.includes('ROLE_OWNER'));
        } else {
          //setIsLoading(false);
        }
      } catch (e) {
        console.log(e)
        //setIsLoading(false);
        //handleShowMessage('500');
      }

    }

    setLoading(false); // Marca la carga como completada
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        showUser,
        showAdmin,
        showMaster,
        showOwner,
        OwnedProducts,
        AvailableProducts
      }}
    >
      {loading ? <div>Cargando...</div> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };