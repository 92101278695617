import AuthService from "./auth.service";
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';

const MAX_RETRIES = 3; // Número máximo de reintentos

export function handleResponse(response, retries = 0) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // Auto logout si 401 Unauthorized o 403 Forbidden response del API
        if (data && data.message && data.message.includes("expired")) {
          // Manejar el caso específico de token expirado
          console.error("Token expirado. Cerrando sesión...");
          const auth = getAuth();
          firebaseSignOut(auth).then(() => {
            console.log("Sesión cerrada exitosamente.");
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user3');            
          }).catch((error) => {
            console.error("Error al cerrar sesión:", error);
          });
        } else {
          AuthService.logout();
        }
      }

      const error = (data && data.message) || response.statusText;

      // Verificar si se deben hacer más reintentos
      if (retries < MAX_RETRIES) {
        console.error(`Intento ${retries + 1} fallido. Reintentando...`, error);
        return new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          // Realizar un nuevo intento
          return handleResponse(response, retries + 1);
        });
      }

      // Si se supera el número máximo de reintentos, rechazar con el error
      return Promise.reject(error);
    }

    return data;
  });
}
